import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<ToastMessage>();
  private confirmSubject = new Subject<ConfirmMessage>();

  toastState$ = this.toastSubject.asObservable();
  confirmState$ = this.confirmSubject.asObservable();

  showToast(message: string, type: 'success' | 'error' | 'info' = 'success', duration: number = 5000): void {
    this.toastSubject.next({ message, type, duration });
  }

  openConfirm(message: string, onConfirm: () => void, onCancel?: () => void): void {
    this.confirmSubject.next({ message, onConfirm, onCancel });
  }
}

export interface ToastMessage {
  message: string;
  type: 'success' | 'error' | 'info';
  duration: number;
}

export interface ConfirmMessage {
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
}
