<ngx-ui-loader></ngx-ui-loader>

<!-- Banner e Imagens -->
<div class="banner" [ngClass]="{'dark-theme-topbar': currentTheme === 'theme-dark'}">
  <img src="{{company.img_banner_link}}" class="cover" alt="">
</div>

<div class="bannerProfileImg" *ngIf="company.img_cartao_link != undefined" [ngClass]="{'dark-theme-topbar': currentTheme === 'theme-dark'}">
  <div class="profileDiv">
    <img src="{{company.img_cartao_link}}" class="img-fluid profile" alt="">
  </div>
</div>

<div class="main" [ngClass]="{'dark-theme-topbar': currentTheme === 'theme-dark'}">
  <div class="title-clube">
    <h1>Clube de Assinaturas</h1>
  </div>
  
  <div [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
    <div class="subscription-container">
      <div class="card-assinaturas" id="ConhecerAssinaturas">
        <!-- Seção de Assinaturas -->
        <div *ngIf="assinaturas.length == 0">
          <div [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
            <b>Nenhuma assinatura disponível para compra</b>
          </div>
        </div>

        <div *ngIf="assinaturas.length > 0">
          <div [ngClass]="{'dark-theme-card': currentTheme === 'theme-dark'}">
            <h2 class="name-user">Olá, {{ appuserName }}!</h2>
            <div class="description-title">
              <span style="font-weight: 500;">Você ainda não adquiriu nenhuma assinatura</span><br>
              <span>Conheça os planos disponíveis:</span>
            </div>
            <div *ngFor="let assinatura of assinaturas; let firstItem = first">
              <div *ngIf="assinatura.status == 1">
                <p>
                  <input 
                    type="radio" 
                    id="assinatura-{{assinatura.subscription_id}}" 
                    name="assinatura" 
                    [value]="assinatura.subscription_id" 
                    [(ngModel)]="selectedSubscriptionId" 
                    (change)="setPayment(assinatura)">
                  <label class="plan-name" for="assinatura-{{assinatura.subscription_id}}">
                    {{assinatura.name}} por R${{assinatura.price}}
                  </label>
                </p>
                <p class="descPlano">{{assinatura.description}}</p>
                <p class="descPlano">Plano: {{ getPeriodoTexto(assinatura.period) }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Seção de Pagamento PIX e Cartão -->
        <div id="pagamentoPIX">
          <div *ngIf="selectedPaymentMethodId == 7">
            <div [ngClass]="{'dark-theme-card': currentTheme === 'theme-dark'}">
              <div>Informações do PIX:</div>
              <div>
                <br>
                <p>PIX para pagamento - pague pelo aplicativo de seu banco!</p>
                <br>
                <p>Tipo de Chave: {{pixKeyType}}</p>
                <p>Pix: {{pixKey}}</p>
                <p>Beneficiado: {{nomeContaPix}}</p>
              </div>
              <div [style]="{'color':'red'}">ATENÇÃO!</div>
              <div [style]="{'margin-top':'0px'}">Após o pagamento, envie o comprovante para o estabelecimento liberar a sua compra!</div>
            </div>
          </div>
        </div>

        <div id="pagamentoCartao" *ngIf="selectedPaymentMethodId == 2 && paymentLink != null">
          <div [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
            <div>
              <div>Clique no link abaixo para efetuar a sua compra</div>
              <div [style]="{'margin-top':'6px'}">
                <a href="{{paymentLink}}" target="_blank" style="text-decoration:none;">{{paymentLink}}</a>
              </div>
              <div [style]="{'color':'red'}">ATENÇÃO!</div>
              <div [style]="{'margin-top':'0px'}">Após o pagamento, envie o comprovante para o estabelecimento liberar a sua compra!</div>
            </div>
          </div>
        </div>

        <!-- Botão para abrir o Modal de Confirmação -->
        <div class="avanca1Div" id="avanca1Div">
          <button 
            type="button" 
            class="button-plan" 
            (click)="saveAssinaturaRequest()"
            [disabled]="selectedSubscriptionId === null">
            Solicitar adesão ao plano
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="assinatura-title col-12 col-sm-12 d-flex" [ngClass]="{'dark-theme-topbar': currentTheme === 'theme-dark'}">
    <button class="btn-minimalist" (click)="goToCompanyLink()" [ngClass]="{'dark-mode': currentTheme === 'theme-dark'}">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
      <h6>Voltar</h6>
    </button>
  </div>  
</div>

<!-- Modal de Confirmação -->
<div class="modal-overlay" *ngIf="isModalOpen">
  <div class="modal-content">
    <div class="modal-header">
      <h2>Confirmar Envio</h2>
      <!-- <span class="close-button" (click)="closeConfirmacaoModal()">&times;</span> -->
    </div>
    <div class="modal-body">
      <p>Revise as informações abaixo antes de enviar a mensagem:</p>
      <p><strong>Nome do Cliente:</strong> {{ appuserName }}</p>
      <p><strong>Telefone:</strong> {{ appuserPhone }}</p>
      <p><strong>Assinatura:</strong> {{ selectedPayment?.name || 'Não selecionada' }}</p>
      <p><strong>Valor:</strong> R${{ selectedPayment?.price }}</p>
      <p><strong>Período:</strong> {{ selectedPayment?.period }} dias</p>
      <p><strong>Data:</strong> {{ currentDate | date:'dd/MM/yyyy' }} às {{ currentTime | date:'HH:mm:ss' }}</p>
    </div>
    <div class="modal-footer">
      <!-- <button class="btn btn-secondary" (click)="closeConfirmacaoModal()">Cancelar</button> -->
      <button class="btn btn-primary" (click)="confirmSendMessage()" [disabled]="isSending">
        <span *ngIf="!isSending">Enviar Mensagem</span>
        <span *ngIf="isSending">Enviando...</span>
      </button>
    </div>
  </div>
</div>
