import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentOpenpixQrcodeService {
  // private baseUrl = `https://gateway.pandoapps.com.br/api/companies/1/payment`; // URL base para a requisição
  private baseUrl = `${environment.open_pix_url}api/companies/1/payment`; // URL base para a requisição

  constructor(private http: HttpClient) {}

  // Função para enviar a requisição de pagamento
  makePaymentRequest(pix_key_recipient: string, value: number, scheduling_id: number): Observable<any> {
    const payload = {
      pix_key_recipient: pix_key_recipient,
      value: value,  // Valor já em centavos
      scheduling_id: scheduling_id  // Deve ser um número inteiro
    };

    // console.log('Payload para a requisição de pagamento:', payload);

    return this.http.post(this.baseUrl, payload).pipe(
      map((data: any) => {
        // console.log('Resposta da requisição de pagamento:', data);
        return data;
      }),
      catchError((error) => {
        console.error('Erro na requisição de pagamento:', error);
        throw error;
      })
    );
  }

}
