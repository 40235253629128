<ngx-ui-loader></ngx-ui-loader>

<div class="mainBackground" [class]="[currentTheme]">

  <div class="topBlueBackground" [class]="[currentTheme]"> </div>


    <!-- ############################################################ -->
    <!--                            Topbar                            -->
    <!-- ############################################################ -->

    <app-company-top-bar (menuBtnClicked)="closeSideBar()"></app-company-top-bar>

    <app-subscription-feedback [triggerInputAux]="triggerBool"></app-subscription-feedback>

    <div class="cursorPointer marginHorizontalSmall marginVerticalMedium position-absolute top-0 start-0"
            *ngIf="globals.showMenuBtn && globals.showPageMainContent && globals.showTopBar" (click)="openSideBar()">

        <i class="fa fa-bars textBig mainColorPrimary200" aria-hidden="true"></i>

    </div>

    <!-- <div class="autoLayout align-items-start w-100"> -->
    <div class="d-flex align-items-start">
        <!-- ############################################################ -->
        <!--                            Sidebar                           -->
        <!-- ############################################################ -->


        <div class="sideBar" id="sidebar" [ngStyle]="{'left' : ((globals.showPageMainContent && globals.showMenuBtn) || !globals.showTopBar ? '-100%' : '0')}">

            <div class="flex-column" style="margin-left: 4%;">

                <div class="imgNameCompany">

                    <img src="{{company.img_cartao_link}}" class="companyLogo" alt="">

                    <div class="companyInfoDiv">
                        <label class="companyName">Olá,<br>{{nomeCompany}}</label>
                    </div>

                </div>
                <p class="companyName2">O link da sua agenda digital é:</p>
                <div class="div-link">
                  <div class="btn-copy-border">
                    <!-- Link da Empresa -->
                    <a class="linkAgd" [href]="company.handle" target="_blank">{{ company.handle }}</a>
                  </div>
                  <button 
                      class="btn-copy" 
                      ngxClipboard 
                      [cbContent]="company.handle" 
                      (click)="linkCopied = true">
                      <i *ngIf="!linkCopied" class="fa fa-files-o" aria-hidden="true"></i>
                      <i *ngIf="linkCopied" class="fa fa-check" aria-hidden="true"></i>
                    </button>
                </div>
                

            </div>

            <div class="menu lateral autoLayout flex-column">

                  <!-- Menu Lateral -->
<div class="side-menu">
  <!-- 1. Minha agenda -->
    <div class="menuGroupHeader" (click)="toggleMinhaAgenda(); setSelectedButton(1)">
      <span class="groupLabel">
        <i 
        class="fa" 
        [ngClass]="{'fa-chevron-down': isVerAgendaExpanded, 'fa-chevron-right': !isVerAgendaExpanded}"
        aria-hidden="true">
        </i>
        Minha agenda
      </span>
    </div>

  <div *ngIf="isVerAgendaExpanded">

    <!-- Submenu de opções dentro de Minha Agenda -->
    <div class="menuGroup">
      <div class="iconDivSub subItem" (click)="goToAgendamentoList();" [ngClass]="{'selected': selectedButton === 1}">
      <span class="iconLabelSub">
        <i class="fa fa-calendar" aria-hidden="true"></i> Ver calendário
      </span>
      </div>
      <!-- Lista de Espera -->
      <div *ngIf="listaEspera" class="iconDivSub subItem" (click)="navigateAndOpenModal('esperaModal1', 20)" [ngClass]="{'selected': selectedButton === 20}">
        <span class="iconLabelSub">
          <i class="fa fa-th-list" aria-hidden="true"></i>
          Lista de Espera
          <span class="badge" *ngIf="totalEspera > 0">{{ totalEspera }}</span>
        </span>
      </div>
      

      <!-- Confirmar Horários -->
      <div class="iconDivSub subItem" (click)="navigateAndOpenModal('confirmarModal1')" [ngClass]="{'selected': selectedButton === 21}">
        <span class="iconLabelSub">
          <i class="fa fa-check-circle" style="color: green;"></i>
          Confirmar Horários
        </span>
      </div>

      <!-- Bloquear Horários -->
      <div class="iconDivSub subItem" (click)="navigateAndOpenModal('recessoModal1')" [ngClass]="{'selected': selectedButton === 22}">
        <span class="iconLabelSub">
          <i class="fa fa-lock" style="color: red;"></i>
          Bloquear Horários 
          <span *ngIf="blockCount > 0" class="badge">{{ blockCount }}</span>
        </span>
      </div>


      <!-- 2. Histórico de agendamentos -->
      <div 
        class="iconDivSub subItem" 
        (click)="goToAgendaHistory(); setSelectedButton(2)" 
        [ngClass]="{'selected': selectedButton === 2}" 
        *ngIf="historicoAgenda">
        <span class="iconLabelSub">
          <i class="fa fa-history" aria-hidden="true"></i> Histórico de agendamentos
        </span>
      </div>
    </div>
  </div>

  <!-- 3. Cadastros da agenda (Cabeçalho de Grupo com Toggle) -->
  <div class="menuGroupHeader" (click)="toggleCadastrosAgenda()">
    <span class="groupLabel">
      <i 
        class="fa" 
        [ngClass]="{'fa-chevron-down': isCadastrosAgendaExpanded, 'fa-chevron-right': !isCadastrosAgendaExpanded}"
        aria-hidden="true">
      </i>
      Cadastros da agenda
    </span>
  </div>

  <!-- Subitens de Cadastros da agenda -->
  <div *ngIf="isCadastrosAgendaExpanded">
    <!-- Serviços -->
    <div 
      *ngIf="editarAgenda"
      class="iconDivSub subItem" 
      (click)="goToAgendamentoCadastro2(3); setSelectedButton(3)" 
      [ngClass]="{'selected': selectedButton === 3}">
      <span class="iconLabelSub">
        <i class="fa fa-briefcase" aria-hidden="true"></i> Serviços
      </span>
    </div>

    <!-- Profissionais -->
    <div 
      *ngIf="editarAgenda"
      class="iconDivSub subItem" 
      (click)="goToAgendamentoCadastro(1); setSelectedButton(4)" 
      [ngClass]="{'selected': selectedButton === 4}">
      <span class="iconLabelSub">
        <i class="fa fa-user" aria-hidden="true"></i> Profissionais
      </span>
    </div>

    <!-- Horários de atendimento (Sem rota) -->
    <div class="iconDivSub subItem" (click)="goToAgendamentoCadastroHorario(); setSelectedButton(5)" [ngClass]="{'selected': selectedButton === 5}" >
      <span class="iconLabelSub">
        <i class="fa fa-clock" aria-hidden="true"></i> Horários de atendimento
      </span>
    </div>

    <!-- Acesso de colaboradores (Sem rota) -->
    <div *ngIf="gerenciarFuncionarios" class="iconDivSub subItem" (click)="goToAcessoColaboradores(6); setSelectedButton(6)" [ngClass]="{'selected': selectedButton === 6}">
      <span class="iconLabelSub">
        <i class="fa fa-user-plus" aria-hidden="true"></i> Acesso de colaboradores
      </span>
    </div>

    <!-- Configurações da agenda -->
    <div 
      class="iconDivSub subItem" 
      (click)="goToUserProfile(); setSelectedButton(7)" 
      [ngClass]="{'selected': selectedButton === 7}" 
      *ngIf="editarAgenda">
      <span class="iconLabelSub">
        <i class="fa fa-cogs" aria-hidden="true"></i> Configurações da agenda
      </span>
    </div>
  </div>


  <!-- 5. Financeiro (Cabeçalho de Grupo com Toggle) -->
  <div class="menuGroupHeader" (click)="toggleFinanceiro()">
    <span class="groupLabel">
      <i 
        class="fa" 
        [ngClass]="{'fa-chevron-down': isFinanceiroExpanded, 'fa-chevron-right': !isFinanceiroExpanded}"
        aria-hidden="true">
      </i>
      Financeiro
    </span>
  </div>

  <!-- Subitens de Financeiro -->
  <div *ngIf="isFinanceiroExpanded">
    <!-- Controle de Caixa -->
    <div 
      *ngIf="controledecaixa"
      class="iconDivSub subItem" 
      (click)="setSelectedButton(16); goToCaixa()" 
      [ngClass]="{'selected': selectedButton === 16}" 
      >
      <span class="iconLabelSub">
        <i class="fa fa-fax" aria-hidden="true"></i> Controle de Caixa
        <span *ngIf="isCashRegisterOpen" class="status-indicator open"></span>
        <span *ngIf="!isCashRegisterOpen" class="status-indicator closed"></span>
      </span>
    </div>

    <!-- Comandas -->
    <div 
    *ngIf="comandas"
      class="iconDivSub subItem" 
      (click)="setSelectedButton(17); goToComandas()" 
      [ngClass]="{'selected': selectedButton === 17}">
      <span class="iconLabelSub">
        <i class="fa fa-id-card" aria-hidden="true"></i> Comandas
      </span>
    </div>

    <!-- Cálculo de comissões -->
    <div 
      class="iconDivSub subItem" 
      (click)="goToFinancial(); setSelectedButton(8)" 
      [ngClass]="{'selected': selectedButton === 8}" 
      *ngIf="financeiro">
      <span class="iconLabelSub">
        <i class="fa fa-credit-card-alt" aria-hidden="true"></i> Cálculo de comissões
      </span>
    </div>

    <!-- Relatórios de Faturamento -->
    <div 
    class="iconDivSub subItem" 
    (click)="goToDashboard(); setSelectedButton(9)" 
    [ngClass]="{'selected': selectedButton === 9}" 
    *ngIf="relatoriosFaturamento">
    <span class="iconLabelSub">
      <i class="fa fa-chart-simple" aria-hidden="true"></i> Relatório de faturamento
    </span>
    </div>


    <!-- Integração de pagamento -->
    <div 
      class="iconDivSub subItem" 
      (click)="setSelectedButton(15); goToIntegracaoPagamento()" 
      [ngClass]="{'selected': selectedButton === 15}" 
      *ngIf="isAdmin">
      <span class="iconLabelSub">
        <i class="fa fa-qrcode" aria-hidden="true"></i> Pagamentos
      </span>
    </div>
  </div>

  <!-- 4. Clientes e prontuário -->
  <div 
    class="iconDiv" 
    (click)="goToClientes(); setSelectedButton(10)" 
    [ngClass]="{'selected': selectedButton === 10}" 
    *ngIf="clientes">
    <span class="iconLabel">
      <i class="fa fa-users" aria-hidden="true"></i> Clientes e prontuário
    </span>
  </div>

  <!-- 6. Clube de Assinaturas -->
  <div 
    class="iconDiv" 
    (click)="setSelectedButton(18); goToClubeAssinaturas()" 
    [ngClass]="{'selected': selectedButton === 18}">
    <span class="iconLabel">
      <span class="iconLabelGem">
        <i class="fa fa-gem" aria-hidden="true"></i>
      </span>
      
      Clube de Assinaturas
    </span>
  </div>




  <!-- 7. Produtos e Estoque -->
  <div 
    *ngIf="produtoseestoque" 
    class="iconDiv" 
    (click)="setSelectedButton(19); goToProdutos()" 
    [ngClass]="{'selected': selectedButton === 19}" 
    >
    <span class="iconLabel">
      <i class="fa fa-shopping-basket" aria-hidden="true"></i> Produtos e Estoque
    </span>
  </div>

  <!-- 9. Como funciona o WhatsAgenda -->
  <div 
    class="iconDiv" 
    (click)="setSelectedButton(14); goToMeuLink()" 
    [ngClass]="{'selected': selectedButton === 14}" 
    *ngIf="isAdmin">
    <span class="iconLabel">
      <i class="fa fa-question" aria-hidden="true"></i> Como usar
    </span>
  </div>
  </div>
</div>

<div class="assinatura" (click)="goToUsagePlans(); setSelectedButton(18) " [ngClass]="{'selected': selectedButton === 18}">
  <div *ngIf="companyPaymentStatus === 'paid'">
    <h5 class="txt-supp">Você é PRO</h5>
    <span class="txt-planos"> Sua assinatura vence dia: {{assinaturaFim}}</span>
    <button class="btn-planos1" (click)="openPagarmeLink()" *ngIf="planosAssinaturas">Agendar Pagamento</button>
  </div>
  <div *ngIf="companyPaymentStatus === 'null' || !companyPaymentStatus ">
    <h5 class="txt-supp">Torne-se PRO</h5>
    <span class="txt-planos"> Assine o WhatsAgenda e tenha todas as funcionalidades PREMIUM!</span>
    <button class="btn-planos" (click)="goToUsagePlans()" *ngIf="planosAssinaturas">Ver Planos</button>
  </div>
  <ng-container *ngIf="companyPaymentStatus === 'pending_payment' || companyPaymentStatus === 'unpaid'">
    <div class="assinatura1" *ngIf="isPendingPaymentOneDayTolerance(); else elsePaymentPlusOneDayTolerance">
      <img src="assets/ALERTNOPAY.png" class="imgalert">
      <h5 class="txt-supp">Seu plano venceu hoje</h5>
      <span class="txt-planos"> É necessário regularizar o seu plano! Sua assinatura venceu dia: {{assinaturaFim}}</span>
      <button class="btn-planos" (click)="goToUsagePlans()" *ngIf="planosAssinaturas">PAGAR</button>
    </div>
    <ng-template #elsePaymentPlusOneDayTolerance>
      <img src="assets/ALERTNOPAY.png" class="imgalert">
      <h5 class="txt-supp">Sua agenda está bloqueada</h5>
      <span class="txt-planos"> É necessário regularizar o seu plano! Sua assinatura venceu dia: {{assinaturaFim}}</span>
      <button class="btn-planos" (click)="goToUsagePlans()" *ngIf="planosAssinaturas">PAGAR</button>
    </ng-template>
  </ng-container>
</div>

  <div class="assinatura2">
    <h5 class="txt-supp">Suporte</h5>
    <span class="txt-suporte">Fale pelo WhatsApp com nosso suporte. Horário comercial.</span>
    <button class="btn-suporte" (click)="toggleSupportModal()">Falar com Suporte</button>
  </div>

</div>


<!-- Modal de Suporte -->
<div *ngIf="isSupportModalVisible" id="supportModal" class="support-modal" (click)="toggleSupportModal()">
  <div class="support-modal-content animate-modal" (click)="$event.stopPropagation()">
    <h3 class="modal-title">TIRE AS SUAS DÚVIDAS PELOS NOSSOS CANAIS</h3>

    <p class="modal-description">Regularize o seu pagamento</p>
    <button (click)="goToUsagePlans()" class="btn payment-btn animate-pulse">PAGAR MEU PLANO</button>

    <p class="modal-description">Dúvidas frequentes:</p>
    <a href="https://www.youtube.com/watch?v=4ldbthkf6Dc&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=13" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Horário de atendimento</button>
    </a>
    <a href="https://www.youtube.com/watch?v=pPcw9fVynH4&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=8" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Bloquear horário</button>
    </a>
    <a href="https://www.youtube.com/watch?v=Vv57mf00eOw&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=10" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Valor e tempo dos serviços</button>
    </a>
    <a href="https://www.youtube.com/watch?v=VWVfZvhUT40&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=11" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Cadastrar profissionais</button>
    </a>
    <a href="https://www.youtube.com/watch?v=z1ufMjtqmzA&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=15" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Criar acesso para profissionais</button>
    </a>

    <p class="modal-description">
      Sua dúvida não é referente aos itens acima? Veja outros tutoriais completos.
    </p>
    <a href="https://www.youtube.com/playlist?list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8" target="_blank" style="text-decoration: none;">
      <button class="btn youtube-btn animate-button">
        <i class="fa fa-youtube-play"></i> YOUTUBE
      </button>
    </a>

    <p class="modal-description">Ou fale com nosso time de suporte:</p>
    <button (click)="openSuporte()" class="btn support-btn">FALAR NO WHATSAPP</button>

    <p class="support-hours">Atendimento de segunda a sexta das 08:00 às 17:00.</p>
  </div>
</div>



        <!-- ############################################################ -->
        <!--                              Page                            -->
        <!-- ############################################################ -->

        <div class="mainContent" id="pageContent">
            <!-- <div class="w-100" *ngIf="globals.showPageMainContent"> -->
              <div *ngIf="globals.showPageMainContent">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</div>


<!-- -----------------------------------------------------------------------  -->
<!-- Agenda Refresh prompt -->
<!-- -----------------------------------------------------------------------  -->

<!-- <div class="refreshPrompt" [@refreshPromptAnimation] *ngIf="globals.showAgendaRefreshPrompt">

    <label style="font-weight: 600; color: white; margin-top: 2%;">Existem novos agendamentos no sistema, deseja ir para a agenda?</label>

    <div style="display: flex; justify-content: space-evenly; margin-top: 5%;">
      <button class="jumpBtn" style="display: inline-block;" (click)="pauseAlarm(); newAgendaRedirect()">Sim</button>
      <button class="jumpBtn" style="display: inline-block; background-color: white; color: black;" (click)="globals.showAgendaRefreshPrompt = false; pauseAlarm()">Não</button>
    </div>

</div> -->

<div *ngIf="globals.showAlert" [@inOutAnimation] class="mainContainer">
    <div class="modal-header noBorder">
        <h5 class="modal-title msgTitle">{{globals.alert.title}}</h5>
    </div>
    <div class="modal-body noBorder">
        <p>{{globals.alert.message}}</p>
    </div>
    <div class="modal-footer noBorder">
        <button type="button" class="btn blueBtn" (click)="globals.showAlert = false">Ok</button>
    </div>
</div>
