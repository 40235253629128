import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { BaseService } from '../base.service';
import { CompanyUtils } from '../company-utils';
import { AssinaturaCliente } from './assinatura-cliente';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaClienteService extends BaseService  {

  baseURL = `${Config.vBusUrl}subscription_request_edp/`;
  subscriptionUsageURL = `${Config.vBusUrl}subscription_usage_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getSubscriptions() {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getSubscriptionRequests() {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/2`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getSubscriptionUsageForClient(servico_id: number, appuser_id: number, subscription_id: number) {
    const url = `${this.subscriptionUsageURL}${CompanyUtils.getCompanyId()}/${servico_id}/${appuser_id}/${subscription_id}`;
    return this.http.get(url, { responseType: 'text' }).pipe(
        map((response: string) => {
            // console.log("Resposta da API (bruta):", response);

            try {
                const parsedResponse = JSON.parse(response); // Tenta fazer o parsing da resposta
                if (parsedResponse.status === "200" && parsedResponse.result) {
                    return parsedResponse.result; // Retorna o resultado esperado
                } else {
                    throw new Error(parsedResponse.message || 'Erro desconhecido na resposta da API.');
                }
            } catch (e) {
                console.error("Erro ao parsear JSON:", e, "Resposta recebida:", response);
                throw new Error('A resposta da API não está no formato esperado.');
            }
        }),
        catchError((error: HttpErrorResponse) => {
            console.error("Erro no serviço:", error);
            if (error.error instanceof ProgressEvent) {
                return throwError(() => new Error('Erro de conexão com o servidor.'));
            } else if (error.status === 200) {
                return throwError(() => new Error('A resposta da API não está no formato esperado.'));
            } else {
                return throwError(() => new Error('Erro ao obter dados de uso da assinatura.'));
            }
        })
    );
}


  patch(assinatura: AssinaturaCliente) {
    return this.http.patch(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`, JSON.stringify(assinatura))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  post(assinatura: AssinaturaCliente) {

    return this.http.post(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`, JSON.stringify(assinatura))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  delete(id) {
    return this.http.delete(this.baseURL + `/${id}`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }

  restore(id) {
    return this.http.delete(this.baseURL + `/${id}/1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }

  exclude(id) {
    return this.http.delete(this.baseURL + `/${id}/2`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }
}
