import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { CompanyUtils } from './company-utils';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  objErro: any = {};

  constructor(protected http: HttpClient) { }

  getCommonHeaders_compToken2Conditional(pF: boolean): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (pF === true) {
      headers = headers.append('bearer', CompanyUtils.getToken());
    }
    return headers;
  }

  handleErrors(error: HttpErrorResponse) {
    // console.log("APP LOG ERROR: ", error);
    if (error.status == 0) {
      return throwError(() => new Error("Houve uma queda temporária de conexão entre este dispositivo e o nosso servidor. Por favor tente novamente em breve."));
    } else if (error.status == 400) {
      return throwError(() => new Error(error.error.message));
    }
    return throwError(() => new Error(error.error.message));
  }
}
