import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { AppuserAssinaturaService } from 'src/app/svc/appuser/appuser-assinatura.service';
import { AssinaturaPublService } from 'src/app/svc/cliente-assinatura/assinatura.service';
import { Assinatura } from 'src/app/svc/company/assinaturas/assinatura';
import { AssinaturaCliente } from 'src/app/svc/company/assinaturas/assinatura-cliente';
import { AssinaturaClienteService } from 'src/app/svc/company/assinaturas/assinatura-cliente.service';
import { Company } from 'src/app/svc/company/company';
import { CompanyService } from 'src/app/svc/company/company.service';
import { Globals } from 'src/app/svc/globals';
import { PaymentMethod } from 'src/app/svc/payment-method/payment-method';

@Component({
  selector: 'app-nova-assinatura',
  templateUrl: './nova-assinatura.component.html',
  styleUrls: ['./nova-assinatura.component.scss'],
})
export class NovaAssinaturaComponent implements OnInit {

  paymentMethod: any = null;
  assinaturas: Assinatura[] = [];
  pixKeyType: string = '';
  pixKey: string = '';
  nomeContaPix: string = '';
  pixValue: number;
  company = new Company();
  destinationPhone = '';
  paymentMethods: PaymentMethod[] = [];

  assinaturaRequest = new AssinaturaCliente();

  // Dados do Cliente
  appuserName: string = '';
  appuserPhone: string = '';

  // Controladores de seleção de cliente, assinatura e forma de pagamento para venda de assinatura
  selectedClientId: number | null = Number(localStorage.getItem('appuser_id'));
  selectedSubscriptionId: number | null = null; // Definido como number | null
  selectedPaymentMethodId: any;
  
  first: boolean = true;

  paymentLink: string | null = null;
  selectedPayment: Assinatura | null = null;

  hasSelectedSubscription: boolean = false;
  currentTheme: string = 'theme-default';
  currentButtonTheme: string = 'button-theme-default';
  
  // Controle do Modal
  isModalOpen: boolean = false;
  
  // Variáveis para exibir data e hora atuais
  currentDate: Date = new Date();
  currentTime: Date = new Date();
  
  // Controle de envio
  isSending: boolean = false;
  
  constructor(
      private router: Router,
      private ngxLoaderSvc: NgxUiLoaderService,
      private assinaturaPublService: AssinaturaPublService,
      private globals: Globals,
      private companySvc: CompanyService,
      private assinaturaClienteSvc: AssinaturaClienteService,
      private appuserSubscriptionSvc: AppuserAssinaturaService,
    ) { }

  ngOnInit(): void {
    this.getCompanySubscriptions();
    this.getCompanyData();
    this.getPaymentMethods();
    this.loadClientData();
  }

  /**
   * Carrega os dados do cliente (nome e telefone)
   */
  loadClientData(): void {
    // Tentar obter os dados do serviço primeiro
    this.appuserSubscriptionSvc.getClientData().subscribe(
      (result) => {
        this.appuserName = result.name;
        this.appuserPhone = result.phone;
        // console.log('Dados do Cliente via Serviço:', this.appuserName, this.appuserPhone);
      },
      (err) => {
        console.error('Erro ao obter dados do cliente via serviço:', err);
        // Fallback para localStorage
        const clientName = localStorage.getItem('appuser_name');
        const clientPhone = localStorage.getItem('appuser_phone');

        if (clientName) {
          this.appuserName = clientName;
        }

        if (clientPhone) {
          this.appuserPhone = clientPhone;
        }

        // console.log('Dados do Cliente via localStorage:', this.appuserName, this.appuserPhone);
      }
    );
  }

  getCompanySubscriptions(){
    this.ngxLoaderSvc.start();
    this.assinaturaPublService.getCompanySubscriptions().subscribe(
      (result: Assinatura[]) => { // Tipagem adicionada
        this.ngxLoaderSvc.stop();
        this.assinaturas = result;
        // console.log('Assinaturas carregadas:', this.assinaturas);
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.error('Erro ao carregar assinaturas:', err);
        this.globals.openDialog('Erro', 'Não foi possível buscar as informações dos serviços');
      }
    );
  }

  getPeriodoTexto(period: number): string {
    switch (period) {
      case 30:
        return 'Mensal';
      case 90:
        return 'Trimestral';
      case 180:
        return 'Semestral';
      case 365:
        return 'Anual';
      default:
        return `${period} dias`; // Caso não esteja nos valores padrões, exibe em dias
    }
  }
  

  getCompanyData() {
    // Obter dados da empresa, incluindo whatsapp_phone
    this.ngxLoaderSvc.start();
    const companyId = localStorage.getItem('CompanyId');
    this.companySvc.getAgendamentoCompData(companyId).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.company = result;

        if(this.company.tempo_agendar_antecedencia != undefined){
          this.company.tempo_agendar_antecedencia *= 3600000;
        }

        if(this.company.is_login_mandatory && !this.selectedClientId){
          this.router.navigateByUrl('client/login');
          return;
        }

        if(this.company.pix_enable){
          const pixInfoArray = this.company.pix_info.split(';');
          this.pixKeyType = pixInfoArray[0];
          this.pixKey = pixInfoArray[1];
          this.nomeContaPix = pixInfoArray[2];
        }

        try {
          this.company.latitude = Number.parseFloat(this.company.latitude.toString());
          this.company.longitude = Number.parseFloat(this.company.longitude.toString());
        } catch (e) {
          this.company.latitude = 0;
          this.company.longitude = 0;
        }
        this.currentTheme = this.company.linkThemeName ? this.company.linkThemeName : 'theme-default';
        this.destinationPhone = '+55' + this.company.whatsapp_phone.replace(/\D/g, '');
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.error('Erro ao carregar dados da empresa:', err);
        alert("Não foi possível carregar os dados da empresa.");
      }
    );
  }

  getPaymentMethods(){
    this.ngxLoaderSvc.start();
    this.assinaturaPublService.getPaymentMethods().subscribe(
      (result: PaymentMethod[]) => {
        this.ngxLoaderSvc.stop();
        this.paymentMethods = result;
        // console.log('Métodos de pagamento carregados:', this.paymentMethods);
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.error('Erro ao carregar métodos de pagamento:', err);
        this.globals.openDialog('Erro', 'Não foi possível buscar as informações dos serviços');
      }
    );
  }

  homeSubscription(){
    const companyId = localStorage.getItem('CompanyId');
    this.router.navigate([`${companyId}/client/assinatura`]);
  }

  logout(){
    localStorage.removeItem('appuser_id');
    localStorage.removeItem('appuserToken');
    this.router.navigateByUrl('client/login');
  }

  saveAssinaturaRequest() {
    this.setSubscriptionRequestData();

    this.ngxLoaderSvc.start();
    this.assinaturaPublService.postSubscriptionRequest(this.assinaturaRequest).subscribe({
      next: (result) => {
        this.ngxLoaderSvc.stop();
        this.globals.openRedirectModal(
          "O estabelecimento recebeu seu pedido de assinatura! Você será redirecionado para suas assinaturas.",
          () => {
            this.openConfirmacaoModal();
          }
        );
      },
      error: (err) => {
        this.ngxLoaderSvc.stop();
        this.globals.openDialog('Erro', "Ocorreu um erro ao cadastrar a assinatura, tente novamente. Se o erro persistir, entre em contato com a empresa");
      }
    });
  }

  setSubscriptionRequestData() {
    this.assinaturaRequest.appuser_id = this.selectedClientId;
    this.assinaturaRequest.subscription_id = this.selectedSubscriptionId!;
    this.assinaturaRequest.status = 2;
  }

  setPayment(assinatura: Assinatura){
    this.paymentLink = null;
    this.selectedPayment = assinatura;
    this.hasSelectedSubscription = true;

    if(this.selectedPayment.payment_link){
      this.paymentLink = this.selectedPayment.payment_link;
    }

    // Atualizar selectedSubscriptionId como número
    this.selectedSubscriptionId = assinatura.subscription_id;
  }

  goToCompanyLink(){
    this.router.navigateByUrl(this.company.handle);
  }

  // ========================================
  // FUNÇÕES ADICIONAIS PARA WHATSAPP
  // ========================================

  /**
   * Construir a mensagem de solicitação de assinatura para enviar via WhatsApp
   */
  createSubscriptionMessage(): string {
    let message = `📄 *Nova Solicitação de Assinatura*\n\n`;
    
    // console.log('Selected Subscription ID:', this.selectedSubscriptionId);
    // console.log('Available Subscriptions:', this.assinaturas);
    
    const selectedSubscription = this.assinaturas.find(sub => sub.subscription_id === this.selectedSubscriptionId);
    if (selectedSubscription) {
      // console.log('Selected Subscription:', selectedSubscription);
      message += `👤 *Nome do Cliente:* ${this.appuserName}\n`;
      message += `📞 *Telefone:* ${this.appuserPhone}\n\n`;
      message += `💼 *Assinatura:* ${selectedSubscription.name}\n`;
      message += `💰 *Valor:* R$${selectedSubscription.price}\n`;
      message += `📅 *Período:* ${selectedSubscription.period} dias\n`;
    } else {
      message += `👤 *Nome do Cliente:* ${this.appuserName}\n`;
      message += `📞 *Telefone:* ${this.appuserPhone}\n\n`;
      message += `💼 *Assinatura:* Não selecionada\n`;
      // console.log('Nenhuma assinatura correspondente encontrada.');
    }

    const formattedDate = this.currentDate.toLocaleDateString('pt-BR');
    const formattedTime = this.currentTime.toLocaleTimeString('pt-BR');

    message += `📅 *Data:* ${formattedDate} às ${formattedTime}\n`;
    message += `_______\n`;
    message += `✅ Pedido feito no dia ${formattedDate} às ${formattedTime} pelo WhatsAgenda Web`;
    return message;
  }

  /**
   * Gera o link do WhatsApp com base na mensagem e no dispositivo
   */
  buildWhatsLink(message: string): string {
    const ua = navigator.userAgent;
    let whatsLink = '';
    const destinationPhoneFormatted = this.destinationPhone.replace('+', '').replace(/\D/g, ''); // Remove caracteres não numéricos

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // Dispositivo móvel
      whatsLink = `whatsapp://send?phone=${destinationPhoneFormatted}&text=${encodeURIComponent(message)}`;
    } else {
      // Desktop
      whatsLink = `https://web.whatsapp.com/send?phone=${destinationPhoneFormatted}&text=${encodeURIComponent(message)}`;
    }

    return whatsLink;
  }

  /**
   * Abre o link do WhatsApp para enviar a mensagem
   */
  sendWhatsappMessage(message: string): void {
    const link = this.buildWhatsLink(message);
    window.open(link, '_blank');
  }

  /**
   * Abre o modal de confirmação
   */
  openConfirmacaoModal(): void {
    this.isModalOpen = true;
    this.updateCurrentDateTime();
  }

  /**
   * Fecha o modal de confirmação
   */
  closeConfirmacaoModal(): void {
    this.isModalOpen = false;
  }

  /**
   * Atualiza as variáveis de data e hora atuais
   */
  updateCurrentDateTime(): void {
    this.currentDate = new Date();
    this.currentTime = new Date();
  }

  /**
   * Envia a mensagem após a confirmação no modal
   */
  confirmSendMessage(): void {
    this.isSending = true;
    // Construir a mensagem para o WhatsApp
    const mensagem = this.createSubscriptionMessage();

    // Enviar a mensagem via WhatsApp
    this.sendWhatsappMessage(mensagem);

    // Simular um pequeno atraso para a experiência do usuário
    setTimeout(() => {
      // Fechar o modal
      this.closeConfirmacaoModal();
      this.isSending = false;

      // Exibir o modal de confirmação e redirecionar
      this.globals.openRedirectModal(
        "O estabelecimento recebeu seu pedido de assinatura! Você será redirecionado para suas assinaturas.",
        () => {
          this.router.navigate(['client/assinatura']);
        }
      );
    }, 1000); // 1 segundo de atraso
  }
}
