import { Component, OnInit } from '@angular/core';
import { ToastService, ToastMessage, ConfirmMessage } from '../toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  currentToast: ToastMessage | null = null;
  currentConfirm: ConfirmMessage | null = null;
  progressWidth = 100;
  private toastTimeout: any;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    // Gerenciamento de Toast
    this.toastService.toastState$.subscribe((toast: ToastMessage) => {
      this.currentToast = toast;
      this.startProgressBar(toast.duration);
      this.autoCloseToast(toast.duration);
    });

    // Gerenciamento de Modal de Confirmação
    this.toastService.confirmState$.subscribe((confirm: ConfirmMessage) => {
      this.currentConfirm = confirm;
    });
  }

  startProgressBar(duration: number): void {
    this.progressWidth = 100;
    const interval = setInterval(() => {
      this.progressWidth -= 100 / (duration / 40); // Atualiza a cada 40ms
      if (this.progressWidth <= 0) {
        clearInterval(interval);
      }
    }, 40);
  }

  autoCloseToast(duration: number): void {
    clearTimeout(this.toastTimeout);
    this.toastTimeout = setTimeout(() => this.closeToast(), duration);
  }

  closeToast(): void {
    this.currentToast = null;
    this.progressWidth = 100;
    clearTimeout(this.toastTimeout);
  }

  confirmAction(): void {
    if (this.currentConfirm?.onConfirm) {
      this.currentConfirm.onConfirm();
    }
    this.closeConfirm();
  }

  cancelAction(): void {
    this.closeConfirm();
    if (this.currentConfirm?.onCancel) {
      this.currentConfirm.onCancel();
    }
  }
  
  closeConfirm(): void {
    this.currentConfirm = null; // Garante que o modal seja fechado imediatamente
  }
}
