import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Config } from '../config';
import { catchError, map } from 'rxjs/operators';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
import { of, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppuserAgendamentoListService extends BaseService {
  baseURL = `${Config.vWebUrl}client_agendamento_list_edp/`;
  linkURL = `${Config.vWebUrl}client_company_link_edp/`;
  subscriptionCountURL = `${Config.vWebUrl}client_subscription_count_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getClientAgendamentoListConfirmaAgendamento() {
    let queryParams = new HttpParams({fromObject: {
      company_id: localStorage.getItem('CompanyId'),
    }})

    return this.http.get(this.baseURL + '-1', {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(error => {
            return throwError(error);
        })
    );
  }

  getClientAgendamentoList() {
    let queryParams = new HttpParams({fromObject: {
      company_id: localStorage.getItem('CompanyId'),
      appuser_id: localStorage.getItem('appuser_id'),
    }})

    return this.http.get(this.baseURL + '-1', {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(error => {
            return throwError(error);
        })
    );
  }


  // Frontend
  deleteAgendamento(agendamentoId) {
    return this.http.delete(this.baseURL + `${agendamentoId}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }


  checkIfUserIsLinkedToCompany(){
    let company_id = localStorage.getItem('CompanyId');
    let appuser_id = localStorage.getItem('appuser_id');

    return this.http.post(this.linkURL + `${company_id}/${appuser_id}` , {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
    })
    .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

  getSubscriptionCount(company_id: string) {
    return this.http.get(`${this.subscriptionCountURL}/${company_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
    })
    .pipe(
        map((data: any) => {
            if (data.status !== "200") {
                // Lançar erro para ser capturado pelo catchError
                throw new Error(data.message || 'Erro ao obter contagem de assinaturas');
            }
            return data.result;
        }),
        catchError(this.handleErrors)
    );
}

async asyncGetSubscriptionCount(company_id: string): Promise<any> {
    try {
        const result = await this.getSubscriptionCount(company_id).toPromise();
        return result;
    } catch (error) {
        this.handleErrors(error);
        throw error;
    }
}

}
