import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Config } from '../config';
import { catchError, map } from 'rxjs/operators';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';


@Injectable({
  providedIn: 'root'
})
export class AppuserAssinaturaService extends BaseService {
  baseURL = `${Config.vWebUrl}client_data_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getClientData() {

    let queryParams = new HttpParams().set("company_id", localStorage.getItem('CompanyId'));
    queryParams = queryParams.set("appuser_id", localStorage.getItem('appuser_id'));

    return this.http.get(this.baseURL + '-1', {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

}
