import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
import { AssinaturaCliente } from '../company/assinaturas/assinatura-cliente';
import { Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AssinaturaPublService extends BaseService  {

    baseURL = `${Config.vWebUrl}client_subscription_edp/`;
    serviceURL = `${Config.vWebUrl}client_subscription_service_edp/`;
    workersURL = `${Config.vWebUrl}client_subscription_worker_edp/`;
    workhoursURL = `${Config.vWebUrl}client_subscription_workhour_edp/`;
    workerWorkhoursURL = `${Config.vWebUrl}client_worker_workhour_edp/`;
    companySubscriptionsURL = `${Config.vWebUrl}subscriptions_edp/`;
    paymentMethodsURL = `${Config.vWebUrl}payment_methods_edp/`;
    requestURL = `${Config.vWebUrl}client_subscription_request_edp/`;
    subscriptionUsageURL = `${Config.vWebUrl}client_subscription_usage_edp/`;

    constructor(private h: HttpClient) {
        super(h);
    }

    getClientSubscription() {
        let queryParams = new HttpParams().set("company_id", localStorage.getItem('CompanyId'));
        queryParams = queryParams.set("appuser_id", localStorage.getItem('appuser_id'));
    
        return this.http.get(this.baseURL + '-1', {
            context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
            params: queryParams
        })
        .pipe(
            map((data: any) => {
                //
                if (data.status != "200") {
                    catchError(this.handleErrors);
                }
    
                return data.result;
            }),
            catchError(this.handleErrors)
        );
    }

    getServicesForSubscription(subscription_id) {
        return this.http.get(this.serviceURL + `${subscription_id}/-1`)
        .pipe(
            map((data: any) => {
                //
                if (data.status != "200") {
                    catchError(this.handleErrors);
                }
                
                return data.result;
            }),
            catchError(this.handleErrors)
        );
    }
    
    getWorkersForSubscription(subscription_id: number): Observable<any> {
        // Configura o contexto para não exigir o token
        const context = new HttpContext().set(NEEDS_COMPANY_BEARER, false);
    
        return this.http.get(`${this.workersURL}${subscription_id}/-1`, { context }).pipe(
            map((data: any) => {
                if (data.status !== "200") {
                    console.error(`Erro no status da resposta para getWorkersForSubscription:`, data);
                    throw new Error(`Status de resposta inválido: ${data.status}`);
                }
    
                if (!Array.isArray(data.result)) {
                    console.error(`Formato inesperado para data.result em getWorkersForSubscription:`, data.result);
                    throw new Error(`Formato inválido: data.result não é um array.`);
                }
    
                return data.result;
            }),
            catchError((error) => {
                console.error(`Erro capturado em getWorkersForSubscription para subscription_id ${subscription_id}:`, error);
                return throwError(error);
            })
        );
    }
    
    getWorkhoursForSubscription(subscription_id: number): Observable<any> {
        // Configura o contexto para não exigir o token
        const context = new HttpContext().set(NEEDS_COMPANY_BEARER, false);
    
        return this.http.get(`${this.workhoursURL}${subscription_id}/-1`, { context }).pipe(
            map((data: any) => {
                if (data.status !== "200") {
                    console.error(`Erro no status da resposta para getWorkhoursForSubscription:`, data);
                    throw new Error(`Status de resposta inválido: ${data.status}`);
                }
    
                if (!Array.isArray(data.result)) {
                    console.error(`Formato inesperado para data.result em getWorkhoursForSubscription:`, data.result);
                    throw new Error(`Formato inválido: data.result não é um array.`);
                }
    
                return data.result;
            }),
            catchError((error) => {
                console.error(`Erro capturado em getWorkhoursForSubscription para subscription_id ${subscription_id}:`, error);
                return throwError(error);
            })
        );
    }
    

    getCompanySubscriptions() {
        let queryParams = new HttpParams().set("company_id", localStorage.getItem('CompanyId'));
    
        return this.http.get(this.companySubscriptionsURL + '-1', {
            context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
            params: queryParams
        })
        .pipe(
            map((data: any) => {
                //
                if (data.status != "200") {
                    catchError(this.handleErrors);
                }
    
                return data.result;
            }),
            catchError(this.handleErrors)
        );
    }

    getPaymentMethods() {
    
        return this.http.get(this.paymentMethodsURL + '-1', {
            context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
        })
        .pipe(
            map((data: any) => {
                //
                if (data.status != "200") {
                    catchError(this.handleErrors);
                }
    
                return data.result;
            }),
            catchError(this.handleErrors)
        );
    }

    postSubscriptionRequest(assinatura: AssinaturaCliente){
        const obj = JSON.stringify(assinatura);
        return this.http.post(this.requestURL, obj, {
          // headers: this.getCommonHeaders_leads(),
            context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true)
        })
        .pipe(
            map((data: any) => {
                if (data.status != "200") {
                catchError(this.handleErrors);
                }
                return data.message;
            }),
            catchError(this.handleErrors)
        );
    }

    getSubscriptionUsageForClient(servico_id: number, appuser_id: number, subscription_id: number) {
        return this.http.get(this.subscriptionUsageURL + `/${servico_id}/${appuser_id}/${subscription_id}`, {
            context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true),
        })
        .pipe(
            map((data: any) => {
                //
                if (data.status != "200") {
                    catchError(this.handleErrors);
                }
                
                return data.result;
            }),
            catchError(this.handleErrors)
        );
    }

    asyncGetSubscriptionUsage(servico_id: number, appuser_id: number, subscription_id: number):Promise<any>{
        return this.getSubscriptionUsageForClient(servico_id, appuser_id, subscription_id).toPromise()
    }
}
